import { baseConfig } from './base-config';
import { IConfig } from './config.interface';

/**
 * Client specific overrides
 */
export const config: IConfig = {
  ...baseConfig,
  //! All client specific override needs to be applied after here.

  /**
   * Enable this to use default shared api hosted at api.storefront.readyorder.com.au
   */
  apiSubDomainPrefix: null,

  appSubDomainPrefix: 'www',

  appDevSubDomainPrefix: 'storefront',

  repository: 'ReadyOrder_RichmondVillagePizzeria',

  appName: 'RichmondVillagePizzeria',

  domainName: 'richmondvillagepizzeria.com.au',

  mapRootDomain: true,

  storefront: {
    contact: {
      email: [] as { label: string; value: string }[],
      phone: [{ label: '(08) 8234 1900', value: '0882341900' }],
      address: 'Shop 4/200 Richmond Rd, Marleston, SA 5033',
      googleLink: 'https://g.page/RichmondVillagePizzeria?share',
    },
    openingHours: {
      week: { startTime: '4:30PM', endTime: '9:30PM' },
      weekend: { startTime: '4:30PM', endTime: '9:30PM' },
    },
    restaurantId: '1R3Bdsm4gukXPYrN6e',
    gloriaFoodCuid: 'da084966-0e97-4bd4-b09c-cd7ef2763da4',
    gloriaFoodRuid: '567c620c-9a52-4eed-ad52-8710c01c3508',
    gloriaFoodUrl: 'https://pos.globalfoodsoft.com',
  },

  awsMailHostingProvider: {
    ...baseConfig.awsMailHostingProvider,
    emailSubject: 'New Website enquiry',
    emailToAddress: 'help@richmondvillagepizzeria.com.au',
  },
};
