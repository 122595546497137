import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent {
  readonly testimonials = [
    {
      message:
        'I can only say the best pizza ever. Each time I order, it gets better than before. You are amazing guys, keep going.🙏🙏  💓🔥🔥. Garlic Prawn and 4 cheese always make me addicted to order apart from other options.',
      userName: 'Sam Haghani',
    },
    {
      message:
        'Absolutely delicious Super Supreme pizza! I recommend the $30 deal which includes an 18" pizza, 1.25l drink and garlic bread. Add chilli and extra garlic to the pizza for extra kick 👌',
      userName: 'George Vlahakis',
    },
    {
      message:
        'Delicious local pizza shop!! Great value as a meal with garlic bread and soft drinks.Always get BBQ chicken - we add extra BBQ sauce because of the great amount of toppings with variety!Yummy pizza base that is not too thin or thick',
      userName: 'Jayda',
    },
    {
      message:
        'I ordered pizza for a netball club event  and everyone was super happy with pizzas - we had a variety delivered - Australian, Hawaiian, The Lot, Margherita, Vegetarian & Pepperoni. Delicious and well priced. Thanks!',
      userName: 'Catherine Frost',
    },
    {
      message: 'Extremely good pizza. Best in Adelaide without a doubt ❤️',
      userName: 'keya Trivedi',
    },
  ];

  feedbackSlides: OwlOptions = {
    items: 1,
    margin: 0,
    nav: false,
    loop: true,
    dots: true,
    autoplay: true,
    mouseDrag: true,
    autoplayHoverPause: true,
    navText: [
      "<i class='icofont-double-left'></i>",
      "<i class='icofont-double-right'></i>",
    ],
  };
}
