<div id="about" class="story-area ptb-100">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="story-image">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
              <img src="assets/img/about/about-img1.jpg" alt="image" />
              <img src="assets/img/about/about-img2.jpg" alt="image" />
            </div>
            <div class="col-lg-6 col-md-6">
              <img src="assets/img/about/about-img3.jpg" alt="image" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="story-content">
          <div class="section-title">
            <span class="text">About us</span>
            <h2>Welcome To Richmond Village Pizzeria</h2>
          </div>
          <p>
            We offer an extensive range of traditional pizzas including
            Margherita, Napolitana and Meat Lovers. You can also go for our
            Gourmet range and select our Lamb Yiros or Deluxe Seafood pizza.
          </p>
          <p>
            Our mains include Garlic Prawns and Parmigiana to name a couple.
            Also available is a tempting range of delicious pastas from
            Bolognese, to Marinara and Puttanesca. What about some tasty finger
            food? We have you covered with our chicken wings and crispy golden
            wedges.
          </p>
          <br />
          <p>Quality, you can trust</p>
          <div class="quote-info">
            <span> - The Richmond Village Pizzeria Team</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="line-bg"><img src="assets/img/line.png" alt="line" /></div>
</div>
