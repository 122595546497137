<div id="home" class="main-banner video-banner">
  <!-- FIXME: Disabling Video element until it is fully working -->
  <!-- <video
    autoplay="autoplay"
    muted
    loop="true"
    playsinline
    class="video-background"
  >
    <source src="assets/video/video.mp4" type="video/mp4" />
  </video> -->
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="main-banner-content">
          <h1>Richmond Village Pizzeria</h1>
          <h2>The best pizza place in Adelaide</h2>
          <p class="text">
            Here at Richmond Village Pizzeria, we only use the freshest
            ingredients and pride ourselves on the quality of our food. Order
          </p>
          <p class="text">
            Online today for pick up or delivery and let us do all the cooking.
          </p>
          <div class="button-group">
            <app-order-now-button></app-order-now-button>
            <!-- <app-table-reservation-button></app-table-reservation-button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="down_arrow">
    <a href="#about" class="scroll_down" aria-label="Scroll Down"></a>
  </div>
</div>
